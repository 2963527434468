<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="margin-bottom-10 top">
        <div>
          <!-- <Button type="primary" style="margin-right: 10px;" @click="add">{{$t('site_add')}}</Button>
          <Button type="info" @click="toInvitation">{{$t('site_invitation')}}</Button> -->
        </div>

        <div class="search_input">
          <Input v-model="keyword"
                 @on-enter="changeKeyword"
                 @on-search="changeKeyword"
                 :placeholder="$t('site_search_tip')"
                 clearable
                 search
                 :enter-button="true"
          />
        </div>
      </div>
      <!-- <div class="search">
        <div class="search-item">
          <div class="name">
            最近新增成员:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curRecentlyAddedTypeId == item.value ? 'active' : ''" v-for="(item,index) in recentlyAddedTypes" :key="index + 'category'" @click="changeSearch(item.value,'curRecentlyAddedTypeId')">{{item.label}}</div>
            </div>
          </div>
        </div>
      </div> -->
      <Table border :columns="columns" :loading="loading" :data="data" @on-sort-change="sortChange"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('site_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

      <Modal
          v-model="modalStatus"
          :title="modalTit"
          @on-cancel="cancel"
      >
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">
          <FormItem :label="$t('site_table_name')" prop="name">
            <Input :disabled="type == 'check'" v-model="formItem.name" :placeholder="$t('site_add_name_tip')"></Input>
          </FormItem>
          <!--        <FormItem label="租户状态" prop="status">-->
          <!--          <Select v-model="formItem.status" style="width:100%" placeholder="租户状态">-->
          <!--            <Option v-for="item in statusList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
          <!--          </Select>-->
          <!--        </FormItem>-->
          <FormItem :label="$t('site_table_desc')" prop="desc">
            <Input :disabled="type == 'check'"  v-model="formItem.desc" :placeholder="$t('site_add_desc_tip')"></Input>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button @click="cancel">取消</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >确定</Button>
        </div>
      </Modal>
    </div>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '../../components/deleteModal.vue';
export default {
  name: "role",
  data(){
    return{
      page:1,
      pageSize:10,
      total:0,
      data: [],
      loading:false,
      keyword:'',
      columns: [
        {
          title: 'ID',
          key: 'id',
          sortable:'custom',
        },
        {
          title: '用户',
          key: 'user_id',
          align: 'center',
          render: (h, para) => {
            return h('div', {
            }, para.row.user.nickname + '('+para.row.user.id+')');
          },
        },
        {
          title: 'model',
          key: 'model',
        },
        {
          title: 'keyword',
          key: 'keyword',
        },
        {
          title: 'result',
          key: 'result',
        },
        {
            title: this.$t('user_created_at'),
            key: 'created_at',
            sortable:'custom',
            render: (h, para) => {
                if (para.row.created_at) {
                  return h('span', util.timeFormatter(new Date(+para.row.created_at*1000), 'yyyy-MM-dd hh:mm'))
                }
            },
        },
      ],
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      modalStatus:false,
      formItem:{
        id:0,
        name:'',
        desc:'',
        status:''
      },
      statusList:[],
      statusObj:{},
      ruleValidate: {
        name: [
          { required: true, message: this.$t('site_add_name_tip'), trigger: 'blur' }
        ],
        desc: [
          { required: false, message: this.$t('site_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      // siteStatus:'',
      // statusList:[
      //   {
      //     label:'全部',
      //     value:1,
      //   },
      //   {
      //     label:'已激活',
      //     value:2,
      //   },
      //   {
      //     label:'未激活',
      //     value:3,
      //   },
      //   {
      //     label:'已禁用',
      //     value:4,
      //   },
      // ],
      // siteId:'',
      // siteName:'',
      // siteNickname:'',
      // siteCompany:'',
      // searchParams:{},
      typesObj:{},
      service_typesObj:{},
      orderby:'',
      sort:'',
      recentlyAddedTypes:{},
      curRecentlyAddedTypeId:'-1'
    }
  },
  components:{
    DeleModal
  },
  mounted(){
    this.getList();
  },
  watch: {
    keyword () {
      if (!this.keyword) {
        this.changeKeyword();
      }
    }
  },
  methods:{
    // search(){
    //
    // },
    changeSearch(data,name){
      this[name] = data;
      this.getList();
    },
    sortChange(e){
      console.log(e,'orderorderorder')
      if(e.order == 'asc' || e.order == 'desc'){
        this.sort = e.order;
        this.orderby = e.key;
      }else{
        this.sort = '';
        this.orderby = '';
      }
      this.getList();
    },
    changeKeyword () {
      this.page = 1;
      this.getList();
    },
    add(){
      this.modalTit = this.$t('site_add');
      this.type = '';
      this.formItem = {
        id:0,
        name:'',
        desc:''
      };
      this.modalStatus = true;
    },
    check(data){
      this.modalTit = this.$t('site_check');
      this.type = 'check';
      this.formItem = {
        id:0,
        name:data.name,
        desc:data.intro
      };
      this.modalStatus = true;
    },
    edit(data){
      this.modalTit = this.$t('site_edit');
      this.type = 'edit';
      this.formItem = {
        id:data.id,
        name:data.name,
        desc:data.intro
      };
      this.modalStatus = true;
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword: this.keyword,
        orderby:this.orderby,
        sort:this.sort,
        recently_added_type:this.curRecentlyAddedTypeId,
        site_id:-1,
        user_id:-1,
      };
      this.api.system.systemChatgptList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.statusObj = res.statuses;
        this.typesObj = res.types;
        this.service_typesObj = res.service_types;
        this.statusList = [];
        for(let name in res.statuses){
          this.statusList.push({
            label:res.statuses[name],
            value:name,
          })
        }
        this.recentlyAddedTypes = [{
          label:'全部',
          value:'-1'
        }];
        for(let name in res.recently_added_types){
          this.recentlyAddedTypes.push({
            label:res.recently_added_types[name],
            value:name,
          })
        }
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        name:this.curDeleData.name
      }
      this.modalLoading = true;
      this.api.system.permissionDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    ok(){
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            site_id:this.formItem.id,
            name:this.formItem.name,
            intro:this.formItem.desc
          };
          this.addBtnLoading = true;
          if(this.formItem.id){
            this.api.site.siteUpdate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }else{
            this.api.site.siteCreate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.page = 1;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }

        }else{
          console.log(this.modalStatus)
        }
      })
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    },
    toInvitation(){
      this.$router.push({
        path:'/site/invitation',
        query:{

        }
      })
    },
    changeStatus(data){ //改变状态
      let params = {
        site_id:data.id,
      }
      if(data.status == 1){ //禁用
        this.api.site.siteClose(params).then((res)=>{
          this.getList();
        })
      }else{  //激活
        this.api.site.siteOpen(params).then((res)=>{
          this.getList();
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .search{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        margin-right: 10px;
        width: 50px;
        text-align: right;
      }
      >button{
        margin-right: 20px;
      }
    }
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 100px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
